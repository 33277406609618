import { t } from '@config/language'
import settings from '@config/settings'
import { getCookie, setCookie } from '@lib/cookies'
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router'

import { Partner } from '../../views/app/components/sub-menu/_types'

interface PartnersContextType {
  selectedPartners: Array<Partner>
  togglePartner: (partner: Partner) => void
  clearPartners: () => void
  highlightedPartners: Array<string>
  setAvailablePartners: (partners: Array<string>) => void
  availablePartnerIds: Array<string>
}

export const PartnersContext = createContext<PartnersContextType | undefined>(
  undefined
)

export const usePartners = () => {
  const context = useContext(PartnersContext)
  if (!context) {
    throw new Error('usePartners must be used within a PartnersProvider')
  }
  return context
}

const savePartnersToCookies = (partners: Array<Partner>) => {
  setCookie(settings.partnerKey, JSON.stringify(partners))
}

const loadPartnersFromCookies = (): Array<Partner> => {
  const cookieData = getCookie(settings.partnerKey)
  return cookieData ? JSON.parse(cookieData) : []
}

export const PartnersProvider: FC = ({ children }) => {
  const history = useHistory()
  const [authorId, setAuthorId] = useState<string | null>(null)
  const [page, setPage] = useState<string | null>(null)
  const [selectedPartners, setSelectedPartners] = useState<Array<Partner>>([])
  const [availablePartnerIds, setAvailablePartners] = useState<Array<string>>(
    []
  )
  const [highlightedPartners, setHighlightedPartners] = useState<Array<string>>(
    []
  )

  useEffect(() => {
    const initialPartners = loadPartnersFromCookies()
    setSelectedPartners(initialPartners)
    return () => {
      clearPartners()
    }
  }, [])

  useEffect(() => {
    if (availablePartnerIds.length) {
      const unselectedPartners = availablePartnerIds?.filter(
        (p) => !selectedPartners.some((sp) => sp.id === p)
      )
      setHighlightedPartners(unselectedPartners)
    }
  }, [availablePartnerIds, setHighlightedPartners, selectedPartners])

  useEffect(() => {
    const [, newPage, newAuthorId] = history.location?.pathname.split('/')
    const { preservePartners } = history?.location?.state || ({} as any)

    const authorPageLabel = t('author.link')
    const currentPageIsAuthor = newPage === authorPageLabel
    const lastPageIsAuthor = page === authorPageLabel
    const pageResetPossiblyRequired =
      (currentPageIsAuthor && !lastPageIsAuthor) ||
      (!currentPageIsAuthor && lastPageIsAuthor) ||
      (currentPageIsAuthor && lastPageIsAuthor && authorId !== newAuthorId) ||
      (!currentPageIsAuthor && !lastPageIsAuthor && page !== newPage)

    setPage(newPage)

    if (pageResetPossiblyRequired) {
      if (currentPageIsAuthor) {
        setAuthorId(newAuthorId)
      }
      if (preservePartners) {
        history.replace({
          pathname: history.location.pathname,
          state: null,
        })
        return
      }
      clearPartners()
    }
  }, [history.location.pathname, history.location.state])

  useEffect(() => {
    savePartnersToCookies(selectedPartners)
  }, [selectedPartners])

  const togglePartner = (partner: Partner) => {
    const exists = selectedPartners.some((p) => p.id === partner.id)
    if (exists) {
      deselectPartner(partner.id)
      return
    }
    setSelectedPartners((prev) => [...prev, partner])
  }

  const deselectPartner = (id: string) => {
    setSelectedPartners((prev) => prev.filter((p) => p.id !== id))
  }

  const clearPartners = () => {
    setSelectedPartners([])
    setHighlightedPartners([])
    setAvailablePartners([])
  }

  return (
    <PartnersContext.Provider
      value={{
        selectedPartners,
        togglePartner,
        clearPartners,
        highlightedPartners,
        setAvailablePartners,
        availablePartnerIds,
      }}
    >
      {children}
    </PartnersContext.Provider>
  )
}

export const withPartnerHook = (Component) => {
  return (props) => {
    const { setAvailablePartners, selectedPartners } = usePartners()

    return (
      <Component
        {...props}
        setAvailablePartners={setAvailablePartners}
        selectedPartners={selectedPartners}
      />
    )
  }
}
